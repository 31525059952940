/*body, html { 
  font-family: 'Vazir', sans-serif;
  text-decoration: none;
}*/

#root {
  min-height: 100vh;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@font-face {
    font-family: Avenir;
    /* ttc */
    src: url(./fonts/Avenir.ttf) format ('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: CenturyGothic;
    src: url(./fonts/911Fonts.com_CenturyGothicRegular__-_911fonts.com_fonts_mhpY.ttf);
    font-style: normal;
    font-weight: normal;
  }

* {
  font-family: ['Avenir', 'Roboto', 'sans-serif'];
}

a { text-decoration: none; }

.content {
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 100vh;
  margin: auto;
  border-left-style: solid;
  border-right-style: solid;
  border-color: skyblue;
  border-width: 0.5px;
}

.header {
  background-color: aliceblue;
  align-self: center;
  min-height: 100px;
  width: 100%;
  min-width: 400px;
  border-bottom-style: solid;
  border-color: grey;
  border-width: 1px;
}

.header1 {
  background-color: aliceblue;
  align-self: center;
  min-height: 20px;
  width: 100%;
  min-width: 400px;
  max-width: 1200px;
  border-bottom-style: solid;
  border-color: grey;
  border-width: 1px;
}

.sidebar {
	  /*flex: 0 1 auto;*/
	  flex-grow: 0;
	  flex-shrink: 0;
	  min-width: 100px;
}

.flex-row {
  flex-direction: row;
  display: flex;
  padding: 10px;
  /*justify-content: space-between;*/
  align-content: flex-start;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
  display: flex;
  padding: 10px;
}

.flex-item {
  align-self: flex-end;
  margin-left: 2px;
  margin-right: 2px;
  /*margin-top: 5px*/
}
.flex-item-end {
    flex-grow: 1;
    max-width: 100px;
    margin-left: auto;
}

.expand {
  width: 100%;
}

.padding {
    padding: 10px;
}

.stagearea {
  display: flex;
  flex-grow: 1;
}

@media (min-width: 601px) {
  .stagearea {
	  background-color: white;
	  align-content: stretch;
	  align-self: stretch;
	  flex-direction: row-reverse;
	  /**/
    padding: 10px;
    margin: 10px;
  }
}

@media (max-width: 600px) {
  .stagearea {
	  /*background-color: burlywood;*/
    background-color: white;
	  align-self: center;
    padding: 5px;
    margin: 5px;
	  width: 100%;
	  flex-direction: column;
	  flex-wrap: wrap;
	  justify-content: flex-start;
  }
}

.footer {
  background-color: lightgrey;
  /*height: 110px;*/
  width: 100vw;
  display: flex;
  /*align-self: center;*/
  justify-content: space-between;
  align-items: center;
}

.m5 {
    padding: 5px;
}

.p-2 {
    padding: 10px;
}

.footer-text {
    padding: 0px;
    font-size: 12px;
}

.d3chart {
  /*padding: 10px;*/
  background-color: white;
  width: 100%;

}

#svgchart {
  min-height: 200px;
  margin-top: 10px;
  padding-top: 10px;
  background-color: white;
  border-width: 1px;
  border-style: dashed;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grid {
  display: block;
}


.sensor-child {
  display:  flex;
  justify-content: space-between;
  padding: 0px  10px;
}


/*.grid .tick {
  stroke: lightgrey;
  opacity: 0.7;
}

.grid path {
	stroke-width: 1.5;
}*/

.axes {
  color: black;
  fill: none;
  stroke: none;
  stroke-width: 1.5;
  font-size: 12px;
}

.gridline {
  color: rgb(151, 139, 139);
  stroke-width: 0.5;
  /*opacity: 0.3;*/
}

.stationbutton {
  margin:  5px;
}


.logo {
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 1px;
}

/*body {
	background-color: lightgray;
}*/

.reverse {
	flex-direction: row-reverse;
}

.portal {
	min-height: 100%;
}

.header {
	/*min-height: 250px;*/
	
}

.flex-col {
    display: flex;
    flex-direction: column;
    
}

/* 
.navbar {
	background-color: #40516f;
	color:  #ffffff;
	margin:  0px;
	padding: 20px;
}

.navbar-light {
    color: white;
}
*/

.tooltipbox {
  fill: white;
  stroke: black;
}


.nav {
	background-color: white;
	margin:  5px;
}


.side {
	max-width: 992px;
	background-color: white;
	border-width: 1px;
	border-color: black;
	margin:  5px;
}


.main {
	max-width: 992px;
	background-color: white;
	border-width: 1px;
	border-color: black;
	margin:  5px;
}

.tail {
	margin:  5px;
}

.shadow {
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
}

.searchgroup {
    display: flex;
    box-sizing: border-box;
    max-height: 37px;
}

.datepicker {
    /*max-height: 10px;
    padding: 0px;*/
}

.MuiFormControl-root {
	max-width: 250px;
}

.MuiFormControl-input {

}

.datepicker {
	padding: 4px 4px;
}

.legend {
	font-size: 12px;
}

.stackdialog {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.MuiTableHead-root {
	font-weight: bold;
	color: white;
}

.tableheader {
	background-color: gray;
	font-weight: bold;
	color: white;
}

.item-bottom {
  align-items: baseline;
  
}

/* NAVBAR */
.navbar {
  background-color: #40516f;
  padding: 10px;
  position: relative;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: whitesmoke;
}

@media (max-width: 992px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 772px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

@media (min-width: 772px) {
  .menu-icon {
    display: none;
  }
  }

.MuiDrawer-paper {
  opacity: 0.9;
  background-color: #40516f;
}

.MuiListItemText-root {
  text-decoration: none;
  color: black;
}


.navbar-title-large {
    display: flex;
}
    @media (max-width: 1200px) {
    .navbar-title-large {
        display: none;
    }
}

.navbar-title-small {
    display: flex;
}
    @media (max-width: 1200px) {
    .navbar-title-small {
        display: none;
    }
}


.navbar-menu {
    display: none;
}
@media (min-width: 1200px) {
.navbar-menu {
    display: flex;
}
}

/* */
.navbar-logo {
    display: none;
}
@media (min-width: 1200px) {
.navbar-logo {
    display: flex;
}
}

/* */
.navbar-logo-2 {
    display: none;
}
@media (max-width: 1200px) {
.navbar-logo-2 {
    display: flex;
}
}
@media (max-width: 450px) {
.navbar-logo-2 {
    display: none;
}
}

/* */
.navbar-hamburger {
    display: none;
}
@media (max-width: 1200px) {
.navbar-hamburger {
    display: flex;
    flex-grow: 1;
}
}

@media (max-width: 1200px) {
  .secondRow  {
      display: none;
  }
}



/* END NAVBAR */

.resp-table {
    overflow-x: auto;
    width: 100%;
}

/*th 
{
  vertical-align: bottom;
  text-align: center;
}

th span 
{
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}*/

/* cols */
@media screen and (max-width: 1800px) {
  .step-1 {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .step-2 {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .step-3 {
    display: none;
  }
}
@media screen and (max-width: 576px){
  .step-4 {
    display: none;
  }
}
@media screen and (max-width: 328px){
  .step-4 {
    display: none;
  }
}


.col-flex {
  /*display: flex;
  justify-content: space-between;
  width: 100%;*/
}

.small {
  font-size: smaller;
}

.images {
    padding: 10px;
    margin: 10px;
    width: 100%;
}

.flex {
    display: flex;
    justify-items: center;
    align-items: center;
}


.flex-grow {
  align-items: stretch;
  flex-grow: 10;
}

.ellipsize {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.center_image {
  margin: auto;
  width: 100%;
  max-width: 1000px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.center {
  padding-top: 0px;
  text-align: center;
}

.stackrow {
  font-size: 12px;
}

.par {
  padding:20px;
}

.bold {
  font-weight: bold;
}


@media screen and (min-width: 480px) {
    .small_screen {
	display: none;
    }
}

@media screen and (max-width: 480px) {
    .large_screen {
	display: none;
    }
}

/* Drag&Drop*/
.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px gray dashed;
  width: 60%;
  margin: auto;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
  padding: 10px;
}

.btn:hover {
  background-color: grey;
  color: aliceblue;
 
}

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}

.file-list img {
  /*max-height: 500px;*/
  max-width: 100%;
  padding-right: 10px;
  object-fit: cover;
}

.logo-laimburg {
    margin-top: 5px;
    margin-bottom: 5px;
}

.solid {
    stroke: solid;
}
.dashed {
    stroke-dasharray: 5.5;
}

.label {
    padding: 1px;
    border: solid;
    border-width: 1px;
    font-size: 12px;
    /*font-weight: bold;*/
    text-anchor: middle;
}

.p10 {
  padding: 10px;
}
.p5 {
  padding: 2px;
}